const _ = require('lodash')
const {flagCountryIconType} = require('./constant')

const flagCountryIconTypeTranslation = {
    rounded: 'corner',
    circle: 'round',
    square: 'square'
}

const getBaseFilename = (domainUrl, flagForm, countryCode) => {
    if (!flagCountryIconType[flagForm]) {
        throw new Error(`Unsupported flag form provided (${flagForm}), use one of ${_.values(flagCountryIconType)}`)
    }
    return `${domainUrl}/assets/flags/${flagCountryIconTypeTranslation[flagForm]}/${countryCode}`
}

module.exports = {

    /**
     * get the file name of country flag icon.
     * @param flagCountry {string} the country name of flag
     * @param iconType {string} the type which listed in constant.flagCountryIconType
     */
    getFlagIconUrl: _.curry((domainUrl, flagForm, countryCode) => getBaseFilename(domainUrl, flagForm, countryCode) + '.svg'),
    getFlagIconPng: _.curry((domainUrl, flagForm, countryCode) => getBaseFilename(domainUrl, flagForm, countryCode) + '.png'),
    getFlagIconSrcSet: _.curry((domainUrl, flagForm, countryCode) => {
        const base = getBaseFilename(domainUrl, flagForm, countryCode)
        return `${base}.png, ${base}_2x.png 2x`
    })
}
