/* eslint-disable */
// scraped from https://github.com/wix-private/linguist/blob/master/linguist-server-modules/linguist-language-manager/src/main/resources/languages.json
module.exports = [
  {
    'languageCode': 'fa',
    'name': 'Persian',
    'localizedName': 'فارسی',
    'locale': {
      'flagUrl': 'AFG',
      'locale': 'fa-af'
    },
    'countries': [
      {
        'locale': 'fa-ir',
        'flagUrl': 'IRN'
      }
    ]
  },
  {
    'languageCode': 'fo',
    'name': 'Faroese',
    'localizedName': 'føroyskt',
    'locale': {
      'flagUrl': 'FRO',
      'locale': 'fo-fo'
    },
    'countries': [
      {
        'locale': 'fo-dk',
        'flagUrl': 'DNK'
      }
    ]
  },
  {
    'languageCode': 'ps',
    'name': 'Pashto',
    'localizedName': 'پښتو',
    'locale': {
      'locale': 'ps-af',
      'flagUrl': 'AFG'
    }
  },
  {
    'languageCode': 'sq',
    'name': 'Albanian',
    'localizedName': 'Shqip',
    'locale': {
      'locale': 'sq-al',
      'flagUrl': 'ALB'
    }
  },
  {
    'languageCode': 'hy',
    'name': 'Armenian',
    'localizedName': 'Հայերեն',
    'locale': {
      'flagUrl': 'ARM',
      'locale': 'hy-am'
    }
  },
  {
    'languageCode': 'bn',
    'name': 'Bengali',
    'localizedName': 'বাংলা',
    'locale': {
      'locale': 'bn-bd',
      'flagUrl': 'BGD'
    }
  },
  {
    'languageCode': 'be',
    'name': 'Belarusian',
    'localizedName': 'беларуская мова',
    'locale': {
      'locale': 'be-by',
      'flagUrl': 'BLR'
    }
  },
  {
    'languageCode': 'yo',
    'name': 'Yoruba',
    'localizedName': 'Yorùbá',
    'locale': {
      'locale': 'yo-bj',
      'flagUrl': 'BEN'
    }
  },
  {
    'languageCode': 'bs',
    'name': 'Bosnian',
    'localizedName': 'Bosanski jezik',
    'locale': {
      'locale': 'bs-ba',
      'flagUrl': 'BIH'
    }
  },
  {
    'languageCode': 'pt',
    'name': 'Portuguese',
    'localizedName': 'português',
    'locale': {
      'flagUrl': 'BRA',
      'locale': 'pt-br'
    },
    'countries': [
      {
        'locale': 'pt-pt',
        'flagUrl': 'PRT'
      },
      {
        'locale': 'pt-cv',
        'flagUrl': 'CPV'
      },
      {
        'locale': 'pt-gq',
        'flagUrl': 'GNQ'
      },
      {
        'locale': 'pt-st',
        'flagUrl': 'STP'
      },
      {
        'locale': 'pt-tl',
        'flagUrl': 'TLS'
      }
    ]
  },
  {
    'languageCode': 'bg',
    'name': 'Bulgarian',
    'localizedName': 'български език',
    'locale': {
      'flagUrl': 'BGR',
      'locale': 'bg-bg'
    }
  },
  {
    'languageCode': 'km',
    'name': 'Khmer',
    'localizedName': 'ខ្មែរ',
    'locale': {
      'locale': 'km-kh',
      'flagUrl': 'KHM'
    }
  },
  {
    'languageCode': 'zh',
    'name': 'Chinese',
    'localizedName': '中文',
    'locale': {
      'locale': 'zh-cn',
      'flagUrl': 'CHN'
    },
    'countries': [
      {
        'locale': 'zh-tw',
        'flagUrl': 'TWN'
      },
      {
        'locale': 'zh-hk',
        'flagUrl': 'HKG'
      },
      {
        'locale': 'zh-sg',
        'flagUrl': 'SGP'
      },
      {
        'locale': 'zh-my',
        'flagUrl': 'MYS'
      },
      {
        'locale': 'zh-mo',
        'flagUrl': 'MAC'
      }
    ]
  },
  {
    'languageCode': 'hr',
    'name': 'Croatian',
    'localizedName': 'Hrvatski jezik',
    'locale': {
      'locale': 'hr-hr',
      'flagUrl': 'HRV'
    }
  },
  {
    'languageCode': 'da',
    'name': 'Danish',
    'localizedName': 'Dansk',
    'locale': {
      'locale': 'da-dk',
      'flagUrl': 'DNK'
    }
  },
  {
    'languageCode': 'et',
    'name': 'Estonian',
    'localizedName': 'Eesti',
    'locale': {
      'locale': 'et-ee',
      'flagUrl': 'EST'
    }
  },
  {
    'languageCode': 'fj',
    'name': 'Fijian',
    'localizedName': 'vosa Vakaviti',
    'locale': {
      'flagUrl': 'FIJ',
      'locale': 'fj-fj'
    }
  },
  {
    'languageCode': 'fi',
    'name': 'Finnish',
    'localizedName': 'Suomi',
    'locale': {
      'flagUrl': 'FIN',
      'locale': 'fi-fi'
    }
  },
  {
    'languageCode': 'fr',
    'name': 'French',
    'localizedName': 'Français',
    'locale': {
      'flagUrl': 'FRA',
      'locale': 'fr-fr'
    },
    'countries': [
      {
        'locale': 'fr-ca',
        'flagUrl': 'CAN'
      },
      {
        'locale': 'fr-be',
        'flagUrl': 'BEL'
      },
      {
        'locale': 'fr-mc',
        'flagUrl': 'MCO'
      },
      {
        'locale': 'fr-ch',
        'flagUrl': 'CHE'
      },
      {
        'locale': 'fr-fr',
        'flagUrl': 'COR'
      },
      {
        'locale': 'fr-fr',
        'flagUrl': 'BRT'
      },
      {
        'locale': 'fr-fr',
        'flagUrl': 'EUS'
      },
      {
        'locale': 'fr-bl',
        'flagUrl': 'BLM'
      },
      {
        'locale': 'fr-ga',
        'flagUrl': 'GAB'
      },
      {
        'locale': 'fr-gp',
        'flagUrl': 'GLP'
      },
      {
        'locale': 'fr-gq',
        'flagUrl': 'GNQ'
      },
      {
        'locale': 'fr-km',
        'flagUrl': 'COM'
      },
      {
        'locale': 'fr-mf',
        'flagUrl': 'MAF'
      },
      {
        'locale': 'fr-mq',
        'flagUrl': 'MTQ'
      },
      {
        'locale': 'fr-nc',
        'flagUrl': 'NCL'
      },
      {
        'locale': 'fr-pm',
        'flagUrl': 'SPM'
      },
      {
        'locale': 'fr-re',
        'flagUrl': 'REU'
      },
      {
        'locale': 'fr-rw',
        'flagUrl': 'RWA'
      },
      {
        'locale': 'fr-sc',
        'flagUrl': 'SYC'
      },
      {
        'locale': 'fr-tf',
        'flagUrl': 'ATF'
      },
      {
        'locale': 'fr-wf',
        'flagUrl': 'WLF'
      },
      {
        'locale': 'fr-yt',
        'flagUrl': 'MYT'
      }
    ]
  },
  {
    'languageCode': 'de',
    'name': 'German',
    'localizedName': 'Deutsch',
    'locale': {
      'flagUrl': 'DEU',
      'locale': 'de-de'
    },
    'countries': [
      {
        'locale': 'de-at',
        'flagUrl': 'AUT'
      },
      {
        'locale': 'de-li',
        'flagUrl': 'LIE'
      },
      {
        'locale': 'de-ch',
        'flagUrl': 'CHE'
      },
      {
        'locale': 'de-be',
        'flagUrl': 'BEL'
      },
      {
        'flagUrl': 'LUX',
        'locale': 'de-lu'
      }
    ]
  },
  {
    'languageCode': 'el',
    'name': 'Greek',
    'localizedName': 'ελληνικά',
    'locale': {
      'flagUrl': 'GRC',
      'locale': 'el-gr'
    }
  },
  {
    'languageCode': 'ht',
    'name': 'Haitian Creole',
    'localizedName': 'Kreyòl ayisyen',
    'locale': {
      'flagUrl': 'HAT',
      'locale': 'ht-ht'
    }
  },
  {
    'languageCode': 'hu',
    'name': 'Hungarian',
    'localizedName': 'Magyar',
    'locale': {
      'flagUrl': 'HUN',
      'locale': 'hu-hu'
    }
  },
  {
    'languageCode': 'is',
    'name': 'Icelandic',
    'localizedName': 'Islenska',
    'locale': {
      'flagUrl': 'ISL',
      'locale': 'is-is'
    }
  },
  {
    'languageCode': 'gu',
    'name': 'Gujarati',
    'localizedName': 'ગુજરાતી',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'gu-in'
    }
  },
  {
    'languageCode': 'hi',
    'name': 'Hindi',
    'localizedName': 'हिन्दी',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'hi-in'
    }
  },
  {
    'languageCode': 'kl',
    'name': 'Greenlandic',
    'localizedName': 'Kalaallisut',
    'locale': {
      'flagUrl': 'GRL',
      'locale': 'kl-gl'
    }
  },
  {
    'languageCode': 'kn',
    'name': 'Kannada',
    'localizedName': 'ಕನ್ನಡ',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'kn-in'
    }
  },
  {
    'languageCode': 'ml',
    'name': 'Malayalam',
    'localizedName': 'മലയാളം',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'ml-in'
    }
  },
  {
    'languageCode': 'sd',
    'name': 'Sindhi',
    'localizedName': 'सिन्धी',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'sd-in'
    }
  },
  {
    'languageCode': 'ta',
    'name': 'Tamil',
    'localizedName': 'தமிழ்',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'ta-in'
    }
  },
  {
    'languageCode': 'te',
    'name': 'Telugu',
    'localizedName': 'తెలుగు',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'te-in'
    }
  },
  {
    'languageCode': 'id',
    'name': 'Indonesian',
    'localizedName': 'Bahasa Indonesia',
    'locale': {
      'flagUrl': 'IDN',
      'locale': 'id-id'
    }
  },
  {
    'languageCode': 'su',
    'name': 'Sundanese',
    'localizedName': 'Basa Sunda',
    'locale': {
      'flagUrl': 'IDN',
      'locale': 'su-id'
    }
  },
  {
    'languageCode': 'ku',
    'name': 'Kurdish',
    'localizedName': 'Kurdî',
    'locale': {
      'flagUrl': 'KUR',
      'locale': 'ku-iq'
    }
  },
  {
    'languageCode': 'ga',
    'name': 'Irish',
    'localizedName': 'Gaeilge',
    'locale': {
      'flagUrl': 'IRL',
      'locale': 'ga-ie'
    }
  },
  {
    'languageCode': 'gl',
    'name': 'Galician',
    'localizedName': 'Galego',
    'locale': {
      'flagUrl': 'GLG',
      'locale': 'gl-ie'
    },
    'countries': [
      {
        'locale': 'gl-es',
        'flagUrl': 'ESP'
      }
    ]
  },
  {
    'languageCode': 'he',
    'name': 'Hebrew',
    'localizedName': 'עברית',
    'locale': {
      'flagUrl': 'ISR',
      'locale': 'he-il'
    }
  },
  {
    'languageCode': 'yi',
    'name': 'Yiddish',
    'localizedName': 'ייִדיש',
    'locale': {
      'flagUrl': 'ISR',
      'locale': 'yi-il'
    }
  },
  {
    'languageCode': 'co',
    'name': 'Corsican',
    'localizedName': 'corsu, lingua corsa',
    'locale': {
      'flagUrl': 'FRA',
      'locale': 'co'
    },
    'countries': [
      {
        'locale': 'co-fr',
        'flagUrl': 'COR'
      }
    ]
  },
  {
    'languageCode': 'it',
    'name': 'Italian',
    'localizedName': 'Italiano',
    'locale': {
      'flagUrl': 'ITA',
      'locale': 'it-it'
    },
    'countries': [
      {
        'locale': 'it-sm',
        'flagUrl': 'SMR'
      },
      {
        'locale': 'it-va',
        'flagUrl': 'VAT'
      }
    ]
  },
  {
    'languageCode': 'la',
    'name': 'Latin',
    'localizedName': 'latine',
    'locale': {
      'flagUrl': 'ITA',
      'locale': 'la-it'
    }
  },
  {
    'languageCode': 'lb',
    'name': 'Luxembourgish',
    'localizedName': 'Lëtzebuergesch',
    'locale': {
      'flagUrl': 'LUX',
      'locale': 'lb-lu'
    }
  },
  {
    'languageCode': 'ja',
    'name': 'Japanese',
    'localizedName': '日本語',
    'locale': {
      'flagUrl': 'JPN',
      'locale': 'ja-jp'
    },
    'countries': [
      {
        'locale': 'ja-pw',
        'flagUrl': 'PLW'
      }
    ]
  },
  {
    'languageCode': 'kk',
    'name': 'Kazakh',
    'localizedName': 'қазақ тілі',
    'locale': {
      'flagUrl': 'KAZ',
      'locale': 'kk-kz'
    }
  },
  {
    'languageCode': 'sw',
    'name': 'Swahili',
    'localizedName': 'Kiswahili',
    'locale': {
      'flagUrl': 'KEN',
      'locale': 'sw-ke'
    },
    'countries': [
      {
        'locale': 'sw-rw',
        'flagUrl': 'RWA'
      }
    ]
  },
  {
    'languageCode': 'ln',
    'name': 'Lingala',
    'localizedName': 'Lingala',
    'locale': {
      'flagUrl': 'COD',
      'locale': 'ln-cd'
    },
    'countries': [
      {
        'flagUrl': 'COG',
        'locale': 'ln-cg'
      }
    ]
  },
  {
    'languageCode': 'lo',
    'name': 'Lao',
    'localizedName': 'ພາສາລາວ',
    'locale': {
      'flagUrl': 'LAO',
      'locale': 'lo-la'
    }
  },
  {
    'languageCode': 'lv',
    'name': 'Latvian',
    'localizedName': 'latviešu valoda',
    'locale': {
      'flagUrl': 'LVA',
      'locale': 'lv-lv'
    }
  },
  {
    'languageCode': 'lt',
    'name': 'Lithuanian',
    'localizedName': 'lietuvių kalba',
    'locale': {
      'flagUrl': 'LTU',
      'locale': 'lt-lt'
    }
  },
  {
    'languageCode': 'mi',
    'name': 'Maori',
    'localizedName': 'Maori',
    'locale': {
      'flagUrl': 'NZL',
      'locale': 'mi'
    }
  },
  {
    'languageCode': 'mk',
    'name': 'Macedonian',
    'localizedName': 'македонски јазик',
    'locale': {
      'flagUrl': 'MKD',
      'locale': 'mk-mk'
    }
  },
  {
    'languageCode': 'mg',
    'name': 'Malagasy',
    'localizedName': 'fiteny malagasy',
    'locale': {
      'flagUrl': 'MDG',
      'locale': 'mg-mg'
    }
  },
  {
    'languageCode': 'mr',
    'name': 'Marathi',
    'localizedName': 'मराठी',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'mr-in'
    }
  },
  {
    'languageCode': 'ms',
    'name': 'Malay',
    'localizedName': 'Bahasa Melayu',
    'locale': {
      'flagUrl': 'ZSM',
      'locale': 'ms'
    }
  },
  {
    'languageCode': 'mt',
    'name': 'Maltese',
    'localizedName': 'Malti',
    'locale': {
      'flagUrl': 'MLT',
      'locale': 'mt-mt'
    }
  },
  {
    'languageCode': 'my',
    'name': 'Burmese',
    'localizedName': 'Burmese',
    'locale': {
      'flagUrl': 'MMR',
      'locale': 'my-mm'
    }
  },
  {
    'languageCode': 'mn',
    'name': 'Mongolian',
    'localizedName': 'монгол',
    'locale': {
      'flagUrl': 'MNG',
      'locale': 'mn-mn'
    }
  },
  {
    'languageCode': 'ne',
    'name': 'Nepali',
    'localizedName': 'नेपाली',
    'locale': {
      'flagUrl': 'NPL',
      'locale': 'ne-np'
    }
  },
  {
    'languageCode': 'ha',
    'name': 'Hausa',
    'localizedName': 'هَوُسَ',
    'locale': {
      'flagUrl': 'HAU',
      'locale': 'ha-ng'
    }
  },
  {
    'languageCode': 'ig',
    'name': 'Igbo',
    'localizedName': 'Asụsụ Igbo',
    'locale': {
      'flagUrl': 'NGA',
      'locale': 'ig-ng'
    }
  },
  {
    'languageCode': 'no',
    'name': 'Norwegian',
    'localizedName': 'Norsk',
    'locale': {
      'flagUrl': 'NOR',
      'locale': 'no-no'
    },
    'countries': [
      {
        'locale': 'no-sj',
        'flagUrl': 'SJM'
      }
    ]
  },
  {
    'languageCode': 'tl',
    'name': 'Tagalog',
    'localizedName': '',
    'locale': {
      'flagUrl': 'PHL',
      'locale': 'tl-ph'
    }
  },
  {
    'languageCode': 'pl',
    'name': 'Polish',
    'localizedName': 'polski',
    'locale': {
      'flagUrl': 'POL',
      'locale': 'pl-pl'
    }
  },
  {
    'languageCode': 'ro',
    'name': 'Romanian',
    'localizedName': 'limba română',
    'locale': {
      'flagUrl': 'ROU',
      'locale': 'ro-ro'
    },
    'countries': [
      {
        'locale': 'ro-md',
        'flagUrl': 'MDA'
      }
    ]
  },
  {
    'languageCode': 'ru',
    'name': 'Russian',
    'localizedName': 'Русский',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'ru-ru'
    },
    'countries': [
      {
        'locale': 'ru-by',
        'flagUrl': 'BLR'
      },
      {
        'locale': 'ru-kz',
        'flagUrl': 'KAZ'
      },
      {
        'locale': 'ru-kg',
        'flagUrl': 'KGZ'
      }
    ]
  },
  {
    'languageCode': 'se',
    'name': 'Sami',
    'localizedName': 'Davvisámegiella',
    'locale': {
      'flagUrl': 'SWE',
      'locale': 'se-se'
    },
    'countries': [
      {
        'flagUrl': 'FIN',
        'locale': 'se-fi'
      },
      {
        'flagUrl': 'NOR',
        'locale': 'se-no'
      }
    ]
  },
  {
    'languageCode': 'sm',
    'name': 'Samoan',
    'localizedName': 'gagana fa\'a Samoa',
    'locale': {
      'flagUrl': 'SMO',
      'locale': 'sm-ws'
    },
    'countries': [
      {
        'locale': 'en-sm',
        'flagUrl': 'ASM'
      }
    ]
  },
  {
    'languageCode': 'sr',
    'name': 'Serbian',
    'localizedName': 'српски језик',
    'locale': {
      'flagUrl': 'SRB',
      'locale': 'sr-rs'
    },
    'countries': [
      {
        'locale': 'sr-me',
        'flagUrl': 'MNE'
      }
    ]
  },
  {
    'languageCode': 'sk',
    'name': 'Slovak',
    'localizedName': 'slovenčina',
    'locale': {
      'flagUrl': 'SVK',
      'locale': 'sk-sk'
    }
  },
  {
    'languageCode': 'si',
    'name': 'Sinhala',
    'localizedName': 'සිංහල',
    'locale': {
      'flagUrl': 'LKA',
      'locale': 'si-lk'
    }
  },
  {
    'languageCode': 'sl',
    'name': 'Slovenian',
    'localizedName': 'slovenski jezik',
    'locale': {
      'locale': 'sl-si',
      'flagUrl': 'SVN'
    }
  },
  {
    'languageCode': 'so',
    'name': 'Somali',
    'localizedName': 'Soomaaliga',
    'locale': {
      'flagUrl': 'SOM',
      'locale': 'so-so'
    }
  },
  {
    'languageCode': 'af',
    'name': 'Afrikaans',
    'localizedName': '',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'af-za'
    }
  },
  {
    'languageCode': 'xh',
    'name': 'Xhosa',
    'localizedName': 'isiXhosa',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'xh-za'
    }
  },
  {
    'languageCode': 'zu',
    'name': 'zulu',
    'localizedName': 'isiZulu',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'zu-za'
    }
  },
  {
    'languageCode': 'ko',
    'name': 'Korean',
    'localizedName': '한국어',
    'locale': {
      'flagUrl': 'KOR',
      'locale': 'ko-kr'
    },
    'countries': [
      {
        'locale': 'ko-kp',
        'flagUrl': 'PRK'
      }
    ]
  },
  {
    'languageCode': 'eu',
    'name': 'Basque',
    'localizedName': 'Euskara',
    'locale': {
      'flagUrl': 'ESP',
      'locale': 'eu-es'
    },
    'countries': [
      {
        'locale': 'eu-es',
        'flagUrl': 'EUS'
      }
    ]
  },
  {
    'languageCode': 'ca',
    'name': 'Catalan',
    'localizedName': 'Català',
    'locale': {
      'flagUrl': 'ESP',
      'locale': 'ca-es'
    },
    'countries': [
      {
        'locale': 'ca-ca',
        'flagUrl': 'CAT'
      },
      {
        'locale': 'ca-es',
        'flagUrl': 'VLC'
      },
      {
        'locale': 'ca-ad',
        'flagUrl': 'AND'
      }
    ]
  },
  {
    'languageCode': 'es',
    'name': 'Spanish',
    'localizedName': 'Español',
    'locale': {
      'flagUrl': 'ESP',
      'locale': 'es-es'
    },
    'countries': [
      {
        'locale': 'es-ar',
        'flagUrl': 'ARG'
      },
      {
        'locale': 'es-mx',
        'flagUrl': 'MEX'
      },
      {
        'locale': 'es-cl',
        'flagUrl': 'CHL'
      },
      {
        'locale': 'es-co',
        'flagUrl': 'COL'
      },
      {
        'locale': 'es-cr',
        'flagUrl': 'CRI'
      },
      {
        'locale': 'es-cu',
        'flagUrl': 'CUB'
      },
      {
        'locale': 'es-do',
        'flagUrl': 'DOM'
      },
      {
        'locale': 'es-ec',
        'flagUrl': 'ECU'
      },
      {
        'locale': 'es-sv',
        'flagUrl': 'SLV'
      },
      {
        'locale': 'es-gt',
        'flagUrl': 'GTM'
      },
      {
        'locale': 'es-hn',
        'flagUrl': 'HND'
      },
      {
        'locale': 'es-bo',
        'flagUrl': 'BOL'
      },
      {
        'locale': 'es-ni',
        'flagUrl': 'NIC'
      },
      {
        'locale': 'es-pa',
        'flagUrl': 'PAN'
      },
      {
        'locale': 'es-py',
        'flagUrl': 'PRY'
      },
      {
        'locale': 'es-pr',
        'flagUrl': 'PRI'
      },
      {
        'locale': 'es-uy',
        'flagUrl': 'URY'
      },
      {
        'locale': 'es-ve',
        'flagUrl': 'VEN'
      },
      {
        'locale': 'es-pe',
        'flagUrl': 'PER'
      },
      {
        'locale': 'es-gq',
        'flagUrl': 'GNQ'
      }
    ]
  },
  {
    'languageCode': 'sv',
    'name': 'Swedish',
    'localizedName': 'svenska',
    'locale': {
      'flagUrl': 'SWE',
      'locale': 'sv-se'
    },
    'countries': [
      {
        'locale': 'sv-ax',
        'flagUrl': 'ALA'
      }
    ]
  },
  {
    'languageCode': 'tg',
    'name': 'Tajik',
    'localizedName': 'тоҷикӣ',
    'locale': {
      'flagUrl': 'TGK',
      'locale': 'tg'
    }
  },
  {
    'languageCode': 'th',
    'name': 'Thai',
    'localizedName': 'ไทย',
    'locale': {
      'flagUrl': 'THA',
      'locale': 'th-th'
    }
  },
  {
    'languageCode': 'tr',
    'name': 'Turkish',
    'localizedName': 'Türkçe',
    'locale': {
      'flagUrl': 'TUR',
      'locale': 'tr-tr'
    }
  },
  {
    'languageCode': 'ug',
    'name': 'Uyghur',
    'localizedName': 'Uyghurche',
    'locale': {
      'flagUrl': 'TUR',
      'locale': 'ug'
    }
  },
  {
    'languageCode': 'uk',
    'name': 'Ukrainian',
    'localizedName': 'українська мова',
    'locale': {
      'flagUrl': 'UKR',
      'locale': 'uk-ua'
    }
  },
  {
    'languageCode': 'cy',
    'name': 'Welsh',
    'localizedName': 'Cymraeg',
    'locale': {
      'flagUrl': 'GBR',
      'locale': 'cy-gb'
    },
    'countries': [
      {
        'locale': 'cy-cy',
        'flagUrl': 'WLS'
      }
    ]
  },
  {
    'languageCode': 'en',
    'name': 'English',
    'localizedName': 'English',
    'locale': {
      'flagUrl': 'USA',
      'locale': 'en-us'
    },
    'countries': [
      {
        'locale': 'en-ca',
        'flagUrl': 'CAN'
      },
      {
        'locale': 'en-au',
        'flagUrl': 'AUS'
      },
      {
        'locale': 'en-gb',
        'flagUrl': 'GBR'
      },
      {
        'locale': 'en-za',
        'flagUrl': 'ZAF'
      },
      {
        'locale': 'en-nz',
        'flagUrl': 'NZL'
      },
      {
        'locale': 'en-ir',
        'flagUrl': 'IRL'
      },
      {
        'locale': 'en-ag',
        'flagUrl': 'ATG'
      },
      {
        'locale': 'en-ai',
        'flagUrl': 'AIA'
      },
      {
        'locale': 'en-as',
        'flagUrl': 'ASM'
      },
      {
        'locale': 'en-bb',
        'flagUrl': 'BRB'
      },
      {
        'locale': 'en-bm',
        'flagUrl': 'BMU'
      },
      {
        'locale': 'en-bs',
        'flagUrl': 'BHS'
      },
      {
        'locale': 'en-bz',
        'flagUrl': 'BLZ'
      },
      {
        'locale': 'en-cw',
        'flagUrl': 'CUW'
      },
      {
        'locale': 'en-dm',
        'flagUrl': 'DMA'
      },
      {
        'locale': 'en-fk',
        'flagUrl': 'FLK'
      },
      {
        'locale': 'en-fm',
        'flagUrl': 'FSM'
      },
      {
        'locale': 'en-gd',
        'flagUrl': 'GRD'
      },
      {
        'locale': 'en-gg',
        'flagUrl': 'GGY'
      },
      {
        'locale': 'en-gi',
        'flagUrl': 'GIB'
      },
      {
        'locale': 'en-gs',
        'flagUrl': 'SGS'
      },
      {
        'locale': 'en-gy',
        'flagUrl': 'GUY'
      },
      {
        'locale': 'en-io',
        'flagUrl': 'IOT'
      },
      {
        'locale': 'en-je',
        'flagUrl': 'JEY'
      },
      {
        'locale': 'en-jm',
        'flagUrl': 'JAM'
      },
      {
        'locale': 'en-ki',
        'flagUrl': 'KIR'
      },
      {
        'locale': 'en-kn',
        'flagUrl': 'KNA'
      },
      {
        'locale': 'en-ky',
        'flagUrl': 'CYM'
      },
      {
        'locale': 'en-lc',
        'flagUrl': 'LCA'
      },
      {
        'locale': 'en-ms',
        'flagUrl': 'MSR'
      },
      {
        'locale': 'en-nf',
        'flagUrl': 'NFK'
      },
      {
        'locale': 'en-nu',
        'flagUrl': 'NIU'
      },
      {
        'locale': 'en-pg',
        'flagUrl': 'PNG'
      },
      {
        'locale': 'en-pn',
        'flagUrl': 'PCN'
      },
      {
        'locale': 'en-pw',
        'flagUrl': 'PLW'
      },
      {
        'locale': 'en-rw',
        'flagUrl': 'RWA'
      },
      {
        'locale': 'en-sb',
        'flagUrl': 'SLB'
      },
      {
        'locale': 'en-sc',
        'flagUrl': 'SYC'
      },
      {
        'locale': 'en-sd',
        'flagUrl': 'SDN'
      },
      {
        'locale': 'en-ss',
        'flagUrl': 'SSD'
      },
      {
        'locale': 'en-sx',
        'flagUrl': 'SXM'
      },
      {
        'locale': 'en-tc',
        'flagUrl': 'TCA'
      },
      {
        'locale': 'en-tk',
        'flagUrl': 'TKL'
      },
      {
        'locale': 'en-tt',
        'flagUrl': 'TTO'
      },
      {
        'locale': 'en-tv',
        'flagUrl': 'TUV'
      },
      {
        'locale': 'en-vc',
        'flagUrl': 'VCT'
      },
      {
        'locale': 'en-vg',
        'flagUrl': 'VGB'
      },
      {
        'locale': 'en-vi',
        'flagUrl': 'VIR'
      }
    ]
  },
  {
    'languageCode': 'uz',
    'name': 'Uzbek',
    'localizedName': 'Oʻzbek',
    'locale': {
      'flagUrl': 'UZB',
      'locale': 'uz-uz'
    }
  },
  {
    'languageCode': 'vi',
    'name': 'Vietnamese',
    'localizedName': 'Việt Nam',
    'locale': {
      'flagUrl': 'VNM',
      'locale': 'vi-vn'
    }
  },
  {
    'languageCode': 'ny',
    'name': 'Chichewa',
    'localizedName': 'ChiCheŵa',
    'locale': {
      'flagUrl': 'MWI',
      'locale': 'ny-mw'
    },
    'countries': [
      {
        'flagUrl': 'ZWE',
        'locale': 'ny-zw'
      },
      {
        'flagUrl': 'ZMB',
        'locale': 'ny-zm'
      },
      {
        'flagUrl': 'MOZ',
        'locale': 'ny-mz'
      }
    ]
  },
  {
    'languageCode': 'sn',
    'name': 'Shona',
    'localizedName': 'chiShona',
    'locale': {
      'flagUrl': 'ZWE',
      'locale': 'sn-zw'
    }
  },
  {
    'languageCode': 'az',
    'name': 'Azerbaijani',
    'localizedName': 'Azərbaycan dili',
    'locale': {
      'flagUrl': 'AZE',
      'locale': 'az-az'
    }
  },
  {
    'languageCode': 'cs',
    'name': 'Czech',
    'localizedName': 'čeština',
    'locale': {
      'flagUrl': 'CZE',
      'locale': 'cs-cz'
    }
  },
  {
    'languageCode': 'am',
    'name': 'Amharic',
    'localizedName': 'አማርኛ',
    'locale': {
      'flagUrl': 'ETH',
      'locale': 'am-et'
    }
  },
  {
    'languageCode': 'ka',
    'name': 'Georgian',
    'localizedName': 'ქართული',
    'locale': {
      'flagUrl': 'GEO',
      'locale': 'ka-ge'
    }
  },
  {
    'languageCode': 'ky',
    'name': 'Kyrgyz',
    'localizedName': 'Кыргызча',
    'locale': {
      'flagUrl': 'KIR',
      'locale': 'ky-kg'
    }
  },
  {
    'languageCode': 'nl',
    'name': 'Dutch',
    'localizedName': 'Nederlands',
    'locale': {
      'flagUrl': 'NLD',
      'locale': 'nl-nl'
    },
    'countries': [
      {
        'locale': 'nl-be',
        'flagUrl': 'BEL'
      },
      {
        'locale': 'nl-aw',
        'flagUrl': 'ASM'
      },
      {
        'locale': 'nl-bq',
        'flagUrl': 'BES'
      },
      {
        'locale': 'nl-cw',
        'flagUrl': 'CUW'
      },
      {
        'locale': 'nl-sr',
        'flagUrl': 'SUR'
      },
      {
        'locale': 'nl-sx',
        'flagUrl': 'SXM'
      }
    ]
  },
  {
    'languageCode': 'ur',
    'name': 'Urdu',
    'localizedName': 'اردو',
    'locale': {
      'flagUrl': 'PAK',
      'locale': 'ur-pk'
    }
  },
  {
    'languageCode': 'ar',
    'name': 'Arabic',
    'localizedName': 'العربية',
    'locale': {
      'flagUrl': 'ARE',
      'locale': 'ar-ae'
    },
    'countries': [
      {
        'locale': 'ar-dz',
        'flagUrl': 'DZA'
      },
      {
        'locale': 'ar-eg',
        'flagUrl': 'EGY'
      },
      {
        'locale': 'ar-iq',
        'flagUrl': 'IRQ'
      },
      {
        'locale': 'ar-jo',
        'flagUrl': 'JOR'
      },
      {
        'locale': 'ar-kw',
        'flagUrl': 'KWT'
      },
      {
        'locale': 'ar-lb',
        'flagUrl': 'LBN'
      },
      {
        'locale': 'ar-ly',
        'flagUrl': 'LBY'
      },
      {
        'locale': 'ar-ma',
        'flagUrl': 'MAR'
      },
      {
        'locale': 'ar-om',
        'flagUrl': 'OMN'
      },
      {
        'locale': 'ar-qa',
        'flagUrl': 'QAT'
      },
      {
        'locale': 'ar-sa',
        'flagUrl': 'SAU'
      },
      {
        'locale': 'ar-so',
        'flagUrl': 'SOM'
      },
      {
        'locale': 'ar-tn',
        'flagUrl': 'TUN'
      },
      {
        'locale': 'ar-ye',
        'flagUrl': 'YEM'
      },
      {
        'locale': 'ar-bh',
        'flagUrl': 'BHR'
      },
      {
        'locale': 'ar-km',
        'flagUrl': 'COM'
      },
      {
        'locale': 'ar-ps',
        'flagUrl': 'PSE'
      },
      {
        'locale': 'ar-sd',
        'flagUrl': 'SDN'
      },
      {
        'locale': 'ar-sy',
        'flagUrl': 'SYR'
      }
    ]
  },
  {
    'languageCode': 'qu',
    'name': 'Quechua',
    'localizedName': 'Runa Simi',
    'locale': {
      'flagUrl': 'PER',
      'locale': 'qu-pe'
    }
  },
  {
    'languageCode': 'tk',
    'name': 'Turkmen',
    'localizedName': 'Türkmençe',
    'locale': {
      'flagUrl': 'TKM',
      'locale': 'tk-tm'
    },
    'countries': [
      {
        'locale': 'tk-ru',
        'flagUrl': 'RUS'
      },
      {
        'locale': 'tk-ir',
        'flagUrl': 'IRN'
      },
      {
        'locale': 'tk-uz',
        'flagUrl': 'UZB'
      },
      {
        'locale': 'tk-af',
        'flagUrl': 'AFG'
      }
    ]
  },
  {
    'languageCode': 'aa',
    'name': 'Afar',
    'localizedName': 'Qafar Af',
    'locale': {
      'flagUrl': 'DJI',
      'locale': 'aa-dj'
    },
    'countries': [
      {
        'locale': 'aa-er',
        'flagUrl': 'ERI'
      },
      {
        'locale': 'aa-et',
        'flagUrl': 'ETH'
      }
    ]
  },
  {
    'languageCode': 'ab',
    'name': 'Abkhazian',
    'localizedName': 'Abkhazian',
    'locale': {
      'flagUrl': 'GEO',
      'locale': 'ab-ge'
    }
  },
  {
    'languageCode': 'ae',
    'name': 'Avestan',
    'localizedName': 'Avestan',
    'locale': {
      'flagUrl': 'IRN',
      'locale': 'ae-ir'
    }
  },
  {
    'languageCode': 'ak',
    'name': 'Akan',
    'localizedName': 'Akan',
    'locale': {
      'flagUrl': 'GHA',
      'locale': 'ak-gh'
    }
  },
  {
    'languageCode': 'an',
    'name': 'Aragonese',
    'localizedName': 'Aragonés',
    'locale': {
      'flagUrl': 'ESP',
      'locale': 'an-es'
    }
  },
  {
    'languageCode': 'as',
    'name': 'Assamese',
    'localizedName': 'অসমীয়া',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'as-in'
    }
  },
  {
    'languageCode': 'av',
    'name': 'Avaric',
    'localizedName': 'авар мацӀ',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'av-ru'
    },
    'countries': [
      {
        'locale': 'av-az',
        'flagUrl': 'AZE'
      },
      {
        'locale': 'av-kz',
        'flagUrl': 'KAZ'
      },
      {
        'locale': 'av-ge',
        'flagUrl': 'GEO'
      },
      {
        'locale': 'av-tr',
        'flagUrl': 'TUR'
      }
    ]
  },
  {
    'languageCode': 'ay',
    'name': 'Aymara',
    'localizedName': 'Aimara',
    'locale': {
      'flagUrl': 'BOL',
      'locale': 'ay-bo'
    },
    'countries': [
      {
        'locale': 'ay-pe',
        'flagUrl': 'PER'
      },
      {
        'locale': 'ay-cl',
        'flagUrl': 'CHL'
      }
    ]
  },
  {
    'languageCode': 'ba',
    'name': 'Bashkir',
    'localizedName': 'Башҡорт теле',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'ba-ru'
    }
  },
  {
    'languageCode': 'bh',
    'name': 'Bihari',
    'localizedName': 'बिहारी',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'bh-in'
    },
    'countries': [
      {
        'locale': 'bh-np',
        'flagUrl': 'NPL'
      }
    ]
  },
  {
    'languageCode': 'bi',
    'name': 'Bislama',
    'localizedName': 'Bichelamar',
    'locale': {
      'flagUrl': 'VUT',
      'locale': 'bi-vu'
    }
  },
  {
    'languageCode': 'bm',
    'name': 'Bambara',
    'localizedName': 'Bamanankan',
    'locale': {
      'flagUrl': 'MLI',
      'locale': 'bm-ml'
    }
  },
  {
    'languageCode': 'bo',
    'name': 'Tibetan',
    'localizedName': 'བོད་སྐད',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'bo-in'
    },
    'countries': [
      {
        'locale': 'bo-np',
        'flagUrl': 'NPL'
      }
    ]
  },
  {
    'languageCode': 'br',
    'name': 'Breton',
    'localizedName': 'Brezhoneg',
    'locale': {
      'flagUrl': 'FRA',
      'locale': 'br-fr'
    }
  },
  {
    'languageCode': 'ce',
    'name': 'Chechen',
    'localizedName': 'Нохчийн мотт',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'ce-ru'
    }
  },
  {
    'languageCode': 'ch',
    'name': 'Chamorro',
    'localizedName': 'Finu\' Chamoru',
    'locale': {
      'flagUrl': 'GUM',
      'locale': 'ch-gu'
    },
    'countries': [
      {
        'locale': 'ch-mp',
        'flagUrl': 'MNP'
      }
    ]
  },
  {
    'languageCode': 'cr',
    'name': 'Cree',
    'localizedName': 'ᓀᐦᐃᔭᐁᐧᐃᐧᐣ',
    'locale': {
      'flagUrl': 'CAN',
      'locale': 'cr-ca'
    },
    'countries': [
      {
        'locale': 'cr-us',
        'flagUrl': 'USA'
      }
    ]
  },
  {
    'languageCode': 'cu',
    'name': 'Church Slavic',
    'localizedName': 'Црькъвьнословѣньскъ ѩзыкъ',
    'locale': {
      'flagUrl': 'EUR',
      'locale': 'cu'
    }
  },
  {
    'languageCode': 'cv',
    'name': 'Chuvash',
    'localizedName': 'чăваш чĕлхи',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'cv-ru'
    }
  },
  {
    'languageCode': 'dv',
    'name': 'Maldivian',
    'localizedName': 'dhivehi',
    'locale': {
      'flagUrl': 'MDV',
      'locale': 'dv-mv'
    }
  },
  {
    'languageCode': 'dz',
    'name': 'Dzongkha',
    'localizedName': 'རྫོང་ཁ་',
    'locale': {
      'flagUrl': 'BTN',
      'locale': 'dz-bt'
    }
  },
  {
    'languageCode': 'ee',
    'name': 'Ewe',
    'localizedName': 'Èʋegbe',
    'locale': {
      'flagUrl': 'GHA',
      'locale': 'ee-gh'
    },
    'countries': [
      {
        'locale': 'ee-tg',
        'flagUrl': 'TGO'
      }
    ]
  },
  {
    'languageCode': 'eo',
    'name': 'Esperanto',
    'localizedName': 'Esperanto',
    'locale': {
      'flagUrl': 'GLO',
      'locale': 'eo'
    }
  },
  {
    'languageCode': 'ff',
    'name': 'Fulah',
    'localizedName': 'Fulfulde',
    'locale': {
      'flagUrl': 'BEN',
      'locale': 'ff-bj'
    },
    'countries': [
      {
        'locale': 'ff-bf',
        'flagUrl': 'BFA'
      },
      {
        'locale': 'ff-gm',
        'flagUrl': 'GMB'
      },
      {
        'locale': 'ff-gh',
        'flagUrl': 'GHA'
      },
      {
        'locale': 'ff-gn',
        'flagUrl': 'GIN'
      },
      {
        'locale': 'ff-gw',
        'flagUrl': 'GNB'
      },
      {
        'locale': 'ff-ci',
        'flagUrl': 'CIV'
      },
      {
        'locale': 'ff-lr',
        'flagUrl': 'LBR'
      },
      {
        'locale': 'ff-ml',
        'flagUrl': 'MLI'
      },
      {
        'locale': 'ff-mr',
        'flagUrl': 'MRT'
      },
      {
        'locale': 'ff-ne',
        'flagUrl': 'NER'
      },
      {
        'locale': 'ff-ng',
        'flagUrl': 'NGA'
      },
      {
        'locale': 'ff-sn',
        'flagUrl': 'SEN'
      },
      {
        'locale': 'ff-sl',
        'flagUrl': 'SLE'
      },
      {
        'locale': 'ff-tg',
        'flagUrl': 'TGO'
      },
      {
        'locale': 'ff-sh',
        'flagUrl': 'SHN'
      }
    ]
  },
  {
    'languageCode': 'fy',
    'name': 'Western Frisian',
    'localizedName': 'Fries',
    'locale': {
      'flagUrl': 'NLD',
      'locale': 'fy-nl'
    }
  },
  {
    'languageCode': 'gd',
    'name': 'Gaelic',
    'localizedName': 'Gàidhlig',
    'locale': {
      'flagUrl': 'CAN',
      'locale': 'gd-ca'
    },
    'countries': [
      {
        'locale': 'gd-gb',
        'flagUrl': 'GBR'
      }
    ]
  },
  {
    'languageCode': 'gn',
    'name': 'Guarani',
    'localizedName': 'avañe’ẽ',
    'locale': {
      'flagUrl': 'PRY',
      'locale': 'gn-py'
    },
    'countries': [
      {
        'locale': 'gn-bo',
        'flagUrl': 'BOL'
      },
      {
        'locale': 'gn-ar',
        'flagUrl': 'ARG'
      }
    ]
  },
  {
    'languageCode': 'gv',
    'name': 'Manx',
    'localizedName': 'Gaelg',
    'locale': {
      'flagUrl': 'IMN',
      'locale': 'gv-im'
    }
  },
  {
    'languageCode': 'ho',
    'name': 'Hiri Motu',
    'localizedName': 'Hiri Motu',
    'locale': {
      'flagUrl': 'PNG',
      'locale': 'ho-pg'
    }
  },
  {
    'languageCode': 'hz',
    'name': 'Herero',
    'localizedName': 'Otjiherero',
    'locale': {
      'flagUrl': 'AGO',
      'locale': 'hz-ao'
    },
    'countries': [
      {
        'locale': 'hz-bw',
        'flagUrl': 'BWA'
      },
      {
        'locale': 'hz-na',
        'flagUrl': 'NAM'
      }
    ]
  },
  {
    'languageCode': 'ia',
    'name': 'Interlingua',
    'localizedName': 'Interlingua',
    'locale': {
      'flagUrl': 'GLO',
      'locale': 'ia'
    }
  },
  {
    'languageCode': 'ie',
    'name': 'Interlingue',
    'localizedName': 'Interlingue',
    'locale': {
      'flagUrl': 'GLO',
      'locale': 'ie'
    }
  },
  {
    'languageCode': 'ii',
    'name': 'Sichuan Yi',
    'localizedName': 'ꆈꌠꉙ',
    'locale': {
      'flagUrl': 'CHN',
      'locale': 'ii-cn'
    }
  },
  {
    'languageCode': 'ik',
    'name': 'Inupiaq',
    'localizedName': 'Iñupiatun',
    'locale': {
      'flagUrl': 'USA',
      'locale': 'ik-us'
    },
    'countries': [
      {
        'locale': 'ik-ca',
        'flagUrl': 'CAN'
      }
    ]
  },
  {
    'languageCode': 'io',
    'name': 'Ido',
    'localizedName': 'Ido',
    'locale': {
      'flagUrl': 'GLO',
      'locale': 'io'
    }
  },
  {
    'languageCode': 'iu',
    'name': 'Inuktitut',
    'localizedName': 'ᐃᓄᒃᑎᑐᑦ',
    'locale': {
      'flagUrl': 'USA',
      'locale': 'iu-us'
    },
    'countries': [
      {
        'locale': 'iu-ca',
        'flagUrl': 'CAN'
      }
    ]
  },
  {
    'languageCode': 'jv',
    'name': 'Javanese',
    'localizedName': 'باسا جاوا',
    'locale': {
      'flagUrl': 'IDN',
      'locale': 'jv-id'
    }
  },
  {
    'languageCode': 'ki',
    'name': 'Kikuyu',
    'localizedName': 'Gĩkũyũ',
    'locale': {
      'flagUrl': 'KEN',
      'locale': 'ki-ke'
    },
    'countries': [
      {
        'locale': 'ki-tz',
        'flagUrl': 'TZA'
      },
      {
        'locale': 'ki-ug',
        'flagUrl': 'UGA'
      }
    ]
  },
  {
    'languageCode': 'kr',
    'name': 'Kanuri',
    'localizedName': 'Kànùrí',
    'locale': {
      'flagUrl': 'NGA',
      'locale': 'kr-ng'
    },
    'countries': [
      {
        'locale': 'kr-ne',
        'flagUrl': 'NER'
      },
      {
        'locale': 'kr-td',
        'flagUrl': 'TCD'
      },
      {
        'locale': 'kr-cm',
        'flagUrl': 'CMR'
      }
    ]
  },
  {
    'languageCode': 'ks',
    'name': 'Kashmiri',
    'localizedName': 'कॉशुर',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'ks-in'
    },
    'countries': [
      {
        'locale': 'ks-pk',
        'flagUrl': 'PAK'
      }
    ]
  },
  {
    'languageCode': 'kw',
    'name': 'Cornish',
    'localizedName': 'Kernewek',
    'locale': {
      'flagUrl': 'GBR',
      'locale': 'kw-gb'
    }
  },
  {
    'languageCode': 'lg',
    'name': 'Ganda',
    'localizedName': 'Luganda',
    'locale': {
      'flagUrl': 'TZA',
      'locale': 'lg-ug'
    }
  },
  {
    'languageCode': 'li',
    'name': 'Limburgan',
    'localizedName': 'Limburgs',
    'locale': {
      'flagUrl': 'NLD',
      'locale': 'li-nl'
    },
    'countries': [
      {
        'locale': 'li-be',
        'flagUrl': 'BEL'
      },
      {
        'locale': 'li-de',
        'flagUrl': 'DEU'
      }
    ]
  },
  {
    'languageCode': 'lu',
    'name': 'Luba-Katanga',
    'localizedName': 'Kiluba',
    'locale': {
      'flagUrl': 'COG',
      'locale': 'lu-cg'
    }
  },
  {
    'languageCode': 'mh',
    'name': 'Marshallese',
    'localizedName': 'Kajin M̧ajeļ',
    'locale': {
      'flagUrl': 'MHL',
      'locale': 'mh-mh'
    },
    'countries': [
      {
        'locale': 'ms-id',
        'flagUrl': 'IDN'
      },
      {
        'locale': 'ms-my',
        'flagUrl': 'MYS'
      },
      {
        'locale': 'ms-sg',
        'flagUrl': 'SGP'
      }
    ]
  },
  {
    'languageCode': 'na',
    'name': 'Nauru',
    'localizedName': 'Dorerin Naoero',
    'locale': {
      'flagUrl': 'NRU',
      'locale': 'na-nr'
    }
  },
  {
    'languageCode': 'nb',
    'name': 'Norwegian Bokmål',
    'localizedName': 'Bokmål',
    'locale': {
      'flagUrl': 'NOR',
      'locale': 'nb-no'
    }
  },
  {
    'languageCode': 'nd',
    'name': 'North Ndebele',
    'localizedName': 'isiNdebele saseNyakatho',
    'locale': {
      'flagUrl': 'ZWE',
      'locale': 'nd-zw'
    }
  },
  {
    'languageCode': 'ng',
    'name': 'Ndonga',
    'localizedName': 'Oshindonga',
    'locale': {
      'flagUrl': 'NAM',
      'locale': 'ng-na'
    }
  },
  {
    'languageCode': 'nn',
    'name': 'Norwegian Nynorsk',
    'localizedName': 'Nynorsk',
    'locale': {
      'flagUrl': 'NOR',
      'locale': 'nn-no'
    }
  },
  {
    'languageCode': 'nr',
    'name': 'South Ndebele',
    'localizedName': 'isiNdebele seSewula',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'nr-za'
    }
  },
  {
    'languageCode': 'nv',
    'name': 'Navajo',
    'localizedName': 'Diné bizaad',
    'locale': {
      'flagUrl': 'USA',
      'locale': 'nv-us'
    }
  },
  {
    'languageCode': 'oc',
    'name': 'Occitan',
    'localizedName': 'Occitan',
    'locale': {
      'flagUrl': 'CAT',
      'locale': 'oc-ca'
    },
    'countries': [
      {
        'locale': 'oc-fr',
        'flagUrl': 'FRA'
      },
      {
        'locale': 'oc-it',
        'flagUrl': 'ITA'
      },
      {
        'locale': 'oc-mc',
        'flagUrl': 'MCO'
      }
    ]
  },
  {
    'languageCode': 'oj',
    'name': 'Ojibwa',
    'localizedName': 'ᐊᓂᐦᔑᓈᐯᒧᐎᓐ',
    'locale': {
      'flagUrl': 'USA',
      'locale': 'oj-us'
    },
    'countries': [
      {
        'locale': 'oj-ca',
        'flagUrl': 'CAN'
      }
    ]
  },
  {
    'languageCode': 'om',
    'name': 'Oromo',
    'localizedName': 'Afaan Oromoo',
    'locale': {
      'flagUrl': 'ETH',
      'locale': 'om-et'
    },
    'countries': [
      {
        'locale': 'om-ke',
        'flagUrl': 'KEN'
      }
    ]
  },
  {
    'languageCode': 'or',
    'name': 'Oriya',
    'localizedName': 'ଓଡ଼ିଆ',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'or-in'
    }
  },
  {
    'languageCode': 'os',
    'name': 'Ossetian',
    'localizedName': 'Ирон ӕвзаг/Дигорон ӕвзаг',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'os-ru'
    },
    'countries': [
      {
        'locale': 'os-ge',
        'flagUrl': 'GEO'
      }
    ]
  },
  {
    'languageCode': 'pa',
    'name': 'Punjabi',
    'localizedName': 'ਪੰਜਾਬੀ',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'pa-in'
    },
    'countries': [
      {
        'locale': 'pa-pk',
        'flagUrl': 'PAK'
      }
    ]
  },
  {
    'languageCode': 'pi',
    'name': 'Pali',
    'localizedName': 'Pāḷi',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'pi-in'
    }
  },
  {
    'languageCode': 'rm',
    'name': 'Romansh',
    'localizedName': 'Rumantsch',
    'locale': {
      'flagUrl': 'CHE',
      'locale': 'rm-ch'
    }
  },
  {
    'languageCode': 'rn',
    'name': 'Rundi',
    'localizedName': 'Ikirundi',
    'locale': {
      'flagUrl': 'BDI',
      'locale': 'rn-bi'
    }
  },
  {
    'languageCode': 'rw',
    'name': 'Kinyarwanda',
    'localizedName': 'Ikinyarwanda',
    'locale': {
      'flagUrl': 'RWA',
      'locale': 'rw-rw'
    },
    'countries': [
      {
        'locale': 'rw-bi',
        'flagUrl': 'BDI'
      }
    ]
  },
  {
    'languageCode': 'sa',
    'name': 'Sanskrit',
    'localizedName': 'संस्कृत',
    'locale': {
      'flagUrl': 'IND',
      'locale': 'sa-in'
    },
    'countries': [
      {
        'locale': 'sa-np',
        'flagUrl': 'NPL'
      }
    ]
  },
  {
    'languageCode': 'sc',
    'name': 'Sardinian',
    'localizedName': 'Sardo',
    'locale': {
      'flagUrl': 'ITA',
      'locale': 'sc-it'
    }
  },
  {
    'languageCode': 'sg',
    'name': 'Sango',
    'localizedName': 'yângâ tî sängö',
    'locale': {
      'flagUrl': 'CAF',
      'locale': 'sg-cf'
    }
  },
  {
    'languageCode': 'ss',
    'name': 'Swati',
    'localizedName': 'siSwati',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'ss-za'
    },
    'countries': [
      {
        'locale': 'ss-sz',
        'flagUrl': 'SWZ'
      }
    ]
  },
  {
    'languageCode': 'st',
    'name': 'Southern Sotho',
    'localizedName': 'Sesotho',
    'locale': {
      'flagUrl': 'LSO',
      'locale': 'st-ls'
    },
    'countries': [
      {
        'locale': 'st-za',
        'flagUrl': 'ZAF'
      },
      {
        'locale': 'st-zw',
        'flagUrl': 'ZWE'
      },
      {
        'locale': 'tg-uz',
        'flagUrl': 'UZB'
      },
      {
        'locale': 'tg-kz',
        'flagUrl': 'KAZ'
      },
      {
        'locale': 'tg-kg',
        'flagUrl': 'KGZ'
      }
    ]
  },
  {
    'languageCode': 'ti',
    'name': 'Tigrinya',
    'localizedName': 'ትግርኛ ',
    'locale': {
      'flagUrl': 'ERI',
      'locale': 'ti-er'
    },
    'countries': [
      {
        'locale': 'ti-et',
        'flagUrl': 'ETH'
      }
    ]
  },
  {
    'languageCode': 'tn',
    'name': 'Tswana',
    'localizedName': 'Setswana',
    'locale': {
      'flagUrl': 'BWA',
      'locale': 'tn-bw'
    },
    'countries': [
      {
        'locale': 'tn-za',
        'flagUrl': 'ZAF'
      },
      {
        'locale': 'tn-zw',
        'flagUrl': 'ZWE'
      }
    ]
  },
  {
    'languageCode': 'to',
    'name': 'Tonga',
    'localizedName': 'lea faka-Tonga',
    'locale': {
      'flagUrl': 'TON',
      'locale': 'to-to'
    }
  },
  {
    'languageCode': 'ts',
    'name': 'Tsonga',
    'localizedName': 'Xitsonga',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 'ts-za'
    },
    'countries': [
      {
        'locale': 'ts-zw',
        'flagUrl': 'ZWE'
      },
      {
        'locale': 'ts-mz',
        'flagUrl': 'MOZ'
      }
    ]
  },
  {
    'languageCode': 'tt',
    'name': 'Tatar',
    'localizedName': 'татар теле',
    'locale': {
      'flagUrl': 'RUS',
      'locale': 'tt-ru'
    },
    'countries': [
      {
        'locale': 'tt-pl',
        'flagUrl': 'RUS'
      }
    ]
  },
  {
    'languageCode': 'tw',
    'name': 'Twi',
    'localizedName': 'Twi',
    'locale': {
      'flagUrl': 'GHA',
      'locale': 'tw-gh'
    }
  },
  {
    'languageCode': 'ty',
    'name': 'Tahitian',
    'localizedName': 'Reo Tahiti',
    'locale': {
      'flagUrl': 'PYF',
      'locale': 'ty-pf'
    }
  },
  {
    'languageCode': 've',
    'name': 'Venda',
    'localizedName': 'Tshivenḓa',
    'locale': {
      'flagUrl': 'ZAF',
      'locale': 've-za'
    }
  },
  {
    'languageCode': 'vo',
    'name': 'Volapük',
    'localizedName': 'Volapük',
    'locale': {
      'flagUrl': 'ZWE',
      'locale': 'vo-zw'
    }
  },
  {
    'languageCode': 'wa',
    'name': 'Walloon',
    'localizedName': 'Waals',
    'locale': {
      'flagUrl': 'BEL',
      'locale': 'wa-be'
    }
  },
  {
    'languageCode': 'wo',
    'name': 'Wolof',
    'localizedName': 'Wolof',
    'locale': {
      'flagUrl': 'SEN',
      'locale': 'wo-sn'
    },
    'countries': [
      {
        'locale': 'wo-gm',
        'flagUrl': 'GMB'
      },
      {
        'locale': 'wo-mr',
        'flagUrl': 'MRT'
      }
    ]
  },
  {
    'languageCode': 'za',
    'name': 'Zhuang',
    'localizedName': '壯語',
    'locale': {
      'flagUrl': 'CHN',
      'locale': 'za-cn'
    }
  }
]
